.page2 {
  background-color: #FF6B00;
  margin-top: -5px;

  .about {
    display: flex;
    justify-content: center;
    padding-left: 88px;
  }

  .heading {
    padding-top: 29px;
    color: #FED900;
    text-shadow: 4px 8px 0px #000;
    font-family: 'Banger';
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 88px */
    text-transform: uppercase;
  }

  .text {
    font-family: 'Banger';
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 35px;
  }

  .line-break-2 {
    margin-top: -225px;
    width: 100%;
    margin-bottom: -120px;
  }

  .content {
    padding: 0 75px;
  }

  .revolution-heading {
    color: #FED900;
    text-shadow: 4px 8px 0px #000;
    font-family: 'Banger';
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 105.6px */
    text-transform: uppercase;
  }

  .content-info {
    display: flex;
    justify-content: space-between;
  }

  .img-token {
    margin-top: -50px;
    width: 434px;
    height: 434px;
    border-radius: 434px;
  }

  .revolution-text-heading {
    font-family: 'Banger';
    font-size: 60px;
    margin: 37px 0px 64px 0px;
  }

  .revolution-text {
    font-family: 'Banger';
    font-size: 32px;
    margin-bottom: 48px;
  }

  .revolution-token-text {
    margin-top: 30px;
    font-family: 'Banger';
    font-size: 90px;
    /* text-align: center; */
  }

  .line-break-1 {
    width: 100%;
    height: 76px;
  }

  .buy-button-long {
    display: flex;
    width: 90%;
    padding: 0px 24px 13px 24px;
    justify-content: center;
    align-items: center;
    gap: 33px;
    border-radius: 149px;
    background: linear-gradient(187deg, #FED900 5.61%, #FFB800 94.38%);
    box-shadow: 7px 7px 0px 0px #000;
    cursor: pointer;
    margin-top: 45px;
    margin-bottom: 170px;
  }

  .buy-button-long-text {
    color: #000;
    font-family: 'Banger';
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .buy-button-long:hover {
    background: linear-gradient(180deg, #FFE029 0%, #FFF097 100%);
  }
}

@media (max-width: 768px) {

  .heading{
    font-size: 70px !important;
  }
  .page2 .about {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-size: 36px;
  }

  .page2 .about-img {
    width: 100%;
    height: auto;
    margin-top: 26px;
  }

  .content-right {
    margin-top: 58px;
    margin-bottom: 30px;
  }

  .page2 .content .content-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page2 .content .content-info .content-left .revolution-heading {
    font-size: 48px;
  }

  .page2 .content .content-info .content-left .revolution-text-heading {
    font-size: 36px;
  }

  .page2 .content .content-info .content-left .revolution-text {
    font-size: 24px;
  }

  .page2 .content .revolution-text {
    font-size: 24px;
  }

  .page2 .content .content-info .content-right .revolution-token-text {
    font-size: 36px;
  }

  .page2 .content .content-info .content-right .img-token {
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .page2 .content .buy-button-long {
    width: 90%;
  }

  .page2 .content .buy-button-long .buy-button-long-text {
    font-size: 36px;
    margin-top: 17px;
  }

  .IconRocket-button {
    display: none;
  }

  .revolution-heading {
    width: 910px !important;
    font-size: 40px !important;
  }

  .line-break-2 {
    margin-top: -98px !important;
    width: 100% !important;
    margin-bottom: -31px !important;
    height: 170px !important;
  }

  .line-break-1 {
    width: 100%;
    height: 35px !important;
  }
}