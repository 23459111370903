.page3 {
    background-color: #FF6B00;
    overflow: hidden;

    .top-content {
        display: grid;
        grid-template-columns: 45% 55%;
        padding-left: 117px;
    }

    .how-to-buy {
        margin-top: 35px;
        margin-bottom: 55px;
        color: #FED900;
        font-family: 'Banger';
        font-size: 120px;
        font-style: normal;
        font-weight: 400;
        text-shadow: 4px 8px 0px #000;
        text-transform: uppercase;
    }

    .step-title {
        color: #000;
        font-family: 'Banger';
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 64px */
    }

    .step-text {
        color: #000;
        font-family: 'Banger';
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 28px;
    }

    .watch-this {
        margin-top: 50px;
        color: #FED900;
        text-shadow: 4px 8px 0px #000;
        font-family: 'Banger';
        font-size: 96px;
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
    }

    .right-image {
        margin-top: -10px;
    }

    .bottom-content {
        padding-left: 117px;
        padding-top: 60px;
        padding-bottom: 100px;
        display: flex;
        gap: 50px;
    }

    .line-break {
        width: 100%;
        height: 76px;
        position: relative;
        z-index: 2;
    }

    .uprising-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 270px;
    }
}

@media (max-width: 768px) {
    .page3 .top-content {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }

    .page3 .how-to-buy {
        font-size: 45px;
        text-align: center;
    }

    .page3 .step-title {
        font-size: 24px;
        margin-left: 10px;
    }

    .page3 .step-text {
        font-size: 18px;
        margin-left: 10px;
    }

    .page3 .watch-this {
        font-size: 48px;
        text-align: center;
    }

    .page3 .right-image {
        width: 60%;
        margin-top: 20px;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 66px;
    }

    .page3 .bottom-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .page3 .image-box {
        margin-bottom: 20px;
    }

    .page3 .image-box .watch-image {
        width: 150px;
        height: 150px;
    }

    .page3 .line-break {
        height: 50px;
    }

    .Social_Icon {
        height: 42px !important;
        width: 40px !important;
    }

    .linebreak4 {
        height: 45px !important;
    }
    .uprisingText {
        height: 22px !important;
    }

    .Icons{
        margin-left: 35px !important;
    }

    .uprising-text {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        margin-left: 10px !important;
    }
}