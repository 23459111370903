.page1 {
    .banner {
        width: 100%;
        height: 720px;
    }

    .sample-video {
        margin-top: -10px;
        width: 100%;
    }

    .page1-content {
        margin-top: -10px;
        background-color: black;
        text-align: center;

        .title {
            padding-top: 87px;
            font-family: 'Banger';
            font-size: 130px;
            color: yellow;
        }

        .ticker {
            padding: 57px 0px;
            font-family: 'Banger';
            font-size: 80px;
            color: white;
        }

        .ca-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 66px;
        }

        .ca-data-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ca-data {
            font-family: 'InterBold';
            font-size: 34px;
            color: white;
            margin: 0px 20px;
        }

        .ca-address {
            width: 75px;
            height: 75px;
        }

        .copy-icon {
            display: flex;
            width: 75px;
            height: 75px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 50%;
            background: linear-gradient(187deg, #FED900 5.61%, #FFB800 94.38%);
            cursor: pointer;
        }

        .copy-icon:hover {
            background: linear-gradient(180deg, #FFE029 0%, #FFF097 100%);
        }
    }

    .line-break-1 {
        width: 100%;
        height: 76px;
    }
}

@media (max-width: 768px) {
    .page1 {
        .banner {
            height: 400px;
        }

        .page1-content {
            .title {
                padding-top: 50px;
                font-size: 80px;
            }

            .ticker {
                padding: 30px 0px;
                font-size: 50px;
            }

            .ca-content {
                flex-direction: column;
                padding-bottom: 30px;
            }

            .ca-data-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .ca-data {
                font-size: 12px;
                margin: 10px 0;
            }

            .ca-address,
            .copy-icon {
                width: 50px;
                height: 50px;
            }
        }

        .line-break-1 {
            height: 50px;
        }
    }
}