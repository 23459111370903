.App {
  overflow: hidden !important;
}

@font-face {
  font-family: 'Banger';
  src: local('Banger'), url(./assets/Fonts/Font_Bangers/Bangers-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'InterRegular';
  src: local('Inter'), url(./assets/Fonts/Font_Inter/static/Inter-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'InterBold';
  src: local('InterBold'), url(./assets/Fonts/Font_Inter/static/Inter-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'InterSemiBold';
  src: local('InterSemiBold'), url(./assets/Fonts/Font_Inter/static/Inter-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'InterLight';
  src: local('InterLight'), url(./assets/Fonts/Font_Inter/static/Inter-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'InterExtraLight';
  src: local('InterExtraLight'), url(./assets/Fonts/Font_Inter/static/Inter-ExtraLight.ttf) format('opentype');
}

@font-face {
  font-family: 'InterMedium';
  src: local('InterMedium'), url(./assets/Fonts/Font_Inter/static/Inter-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'InterThin';
  src: local('InterThin'), url(./assets/Fonts/Font_Inter/static/Inter-Thin.ttf) format('opentype');
}

@font-face {
  font-family: 'InterBlack';
  src: local('InterBlack'), url(./assets/Fonts/Font_Inter/static/Inter-Black.ttf) format('opentype');
}