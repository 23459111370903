.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px;
  background-color: white;
  scroll-behavior: smooth;
}

.logo {
  width: 182px;
  height: 65px;
}

.nav {
  display: flex;
  align-items: center;
  gap: 32px;
}

.nav-item-link {
  text-decoration: none;
}

.nav-item {
  font-family: "Banger";
  font-size: 36px;
  text-decoration: none;
  color: black;
}

.buy-button {
  border-radius: 50px;
  background: linear-gradient(187deg, #fed900 5.61%, #ffb800 94.38%);
  box-shadow: 7px 7px 0px 0px #000;
  display: inline-flex;
  padding: 13px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Banger";
  font-size: 36px;
  cursor: pointer;
  color: black;
}

.buy-button:hover {
  background: linear-gradient(180deg, #ffe029 0%, #fff097 100%);
}

.hamburger {
  display: none;
  cursor: pointer;
}

.line {
  display: block;
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 5px;
}

@media (max-width: 768px) {
  .header {
    padding: 20px;
  }

  .nav-item {
    font-size: 24px;
  }

  .nav-item-link {
    text-decoration: none;
  }

  .buy-button {
    font-size: 24px;
    padding: 10px 20px;
  }

  .hamburger {
    display: block;
  }

  .hamburger.active .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav {
    position: fixed;
    top: 105px;
    left: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    background-color: white;
    padding: 20px 0;
    height: 100vh;
  }

  .nav.active {
    left: 0;
  }
}