.page4 {
    /* background-color: #FF6B00; */
    overflow: hidden;
    height: max-content;

    @media (max-width: 768px) {
        height: 960px;
    }

    .page-title {
        padding-left: 117px;
        padding-right: 150px;
        padding-top: 70px;
        padding-bottom: 50px;
        color: #FED900;
        font-family: 'Banger';
        font-size: 96px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 115.2px */
        text-transform: uppercase;
        text-shadow: 4px 8px 0px #000;
        background-color: #FF6B00;
    }

    .page-content {
        padding-left: 117px;
        padding-right: 80px;
        color: #000;
        font-family: 'Banger';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 38.4px */
        text-transform: uppercase;
        background-color: #FF6B00;
    }

    .img {
        width: 100%;
    }

    .hor-img {
        height: 520px;
    }

    .line-5 {
        margin-top: -97px;
        position: relative;
        z-index: 2;
        height: 150px;
    }

    .hor-carousel {
        /* height: 797px !important; */
        position: relative;
        z-index: 1;
        top: -84px;
        /* display: grid; */
        grid-template-columns: 70% 5% 25%;
        overflow: none;
        background-color: #000;
    }

    .line-6 {
        margin-top: -94px;
        position: relative;
        background-color: #000;
        height: 67px;
    }

    .empty-block {
        margin-top: -56px;
        background-color: #BBBBBB;
        height: 722px;
        position: relative;
    }

    .line-7 {
        height: 85px;
        background-color: #000;
    }
}

@media (min-width: 967px) {}

@media (max-width: 768px) {
    .page4 .page-title {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 48px;
    }

    .page4 .page-content {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 18px;
    }

    .page4 .img {
        width: 100%;
    }

    .page4 .hor-carousel {
        display: block;
        margin-top: 42px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .page4 .hor-img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .page4 .empty-block {
        margin-top: -20px;
        height: 300px;
    }

    .page4 .hor2-img {
        display: none;
    }

    .slick-slide {
        padding: 0 10px;
        /* Adjust padding to create space between slides */
    }

    .line-5 {
        margin-top: -69px !important;
        position: relative !important;
        z-index: 2 !important;
        height: 77px !important;
    }

    .line-6 {
        margin-top: -94px;
        position: relative;
        background-color: #000;
        height: 100px !important;
    }
}