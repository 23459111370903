.page5 {
    .sample-video {
        margin-top: -10px;
        width: 100%;
    }

    .bottom-section {
        margin-top: 25px;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .left {
        margin-left: 117px;
    }

    .icons-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
    }

    .social-icon {
        width: 75px;
        height: 75px;
        border-left: 3px solid black;
    }

    .extra-padding {
        padding-top: 20px;
        padding-left: 20px;
    }

    .twitter {
        padding-top: 25px;
    }

    .tiktok {
        border-right: 3px solid black;
    }

    .bottom-text {
        color: #000;
        font-family: 'Banger';
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 129.007%; /* 46.443px */
    }

    .bottom-info {
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 129.007%; /* 15.481px */
    }

    .disclaimer {
        margin-top: 70px;
    }

    .copy-right {
        margin-top: 25px;
        margin-bottom: 50px;
    };

    .buy-button-medium {
        margin-left: 50px;
        display: flex;
        width: 463px;
        padding: 13px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: linear-gradient(187deg, #FED900 5.61%, #FFB800 94.38%);
        box-shadow: 7px 7px 0px 0px #000;
        color: #000;
        font-family: 'Banger';
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
    }

    .buy-button-medium:hover {
        background: linear-gradient(180deg, #FFE029 0%, #FFF097 100%);
    }
}

@media (max-width: 768px) {
    .page5 .bottom-section {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }

    .page5 .left {
        margin-left: 0;
    }

    .page5 .icons-wrapper {
        justify-content: center;
    }

    .page5 .social-icon {
        width: 50px;
        height: 50px;
    }

    .page5 .img {
        width: 50px;
        height: 50px;
    }

    .page5 .extra-padding {
        padding: 12px 0px 0px 12px;
        width: 25px;
        height: 25px;
    }

    .page5 .bottom-text {
        text-align: center;
        font-size: 24px;
    }

    .page5 .bottom-info {
        font-size: 10px;
    }

    .page5 .buy-button-medium {
        width: 80%;
        margin-left: 0;
        font-size: 48px;
        margin-bottom: 100px;
    }
}